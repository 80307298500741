import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import {MatDialog} from '@angular/material/dialog';
import { UnAuthComponent } from '../shared/un-auth/un-auth.component';
import { UtilitiesService } from './../shared/utilities/utilities.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private cookieService: CookieService
    , private router: Router
    , private _utilityService: UtilitiesService
  ) { }

  isLoggedin() {
    if (this.cookieService.get('token')) {
      setTimeout(() => {
        if (!sessionStorage.getItem('token')) {
          sessionStorage.setItem('token', this.cookieService.get('token'));
        } else if (sessionStorage.getItem('token') !== this.cookieService.get('token')) {
          sessionStorage.removeItem('token');
          window.location.reload();
        }
      });
      return true;
    } else {
      return false;
    }
  }
  deleteAllCookies() {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }
  logout() {
     this.cookieService.deleteAll('/');
    this.deleteAllCookies();
    this.router.navigate(['/']);
    sessionStorage.removeItem('token');
  }
}
