<div class='text-right line-height-0'>
  <button class="btnclose" [mat-dialog-close] tabindex="-1"></button>
</div>
<mat-dialog-content class="unauthPOpup">
  <div class="text-center">
    <img class="warningSIgn mb-2 " src="assets/images/warning-board.png">
    <h4>
      {{data}}
    </h4>
  </div>
  </mat-dialog-content>
