<router-outlet>

    <div class="tabpromt">
        <p>Please Visit from Desktop</p>
    </div>
</router-outlet>
<ngx-spinner
bdOpacity = 0.9
bdColor = "rgba(51,51,51,0.29)"
size = "medium"
color = "#fff"
type = "ball-beat"
[fullScreen] = "true"
>
<p style="color: white" > Loading </p>
</ngx-spinner>

