import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { disableCOloumns } from '../keyValuePair';
@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor(private _location: Location) { }
  public businessPermissions = new BehaviorSubject({});
  private businessID = new BehaviorSubject('');

  private messageBusinessID = new BehaviorSubject('');
  private profileChange = new BehaviorSubject('');
  profilepic = this.profileChange.asObservable();
  currentBusinessID = this.businessID.asObservable();
  private businessIDForResetMessage = new BehaviorSubject('');
  currentBusinessIDForResetMessage = this.businessIDForResetMessage.asObservable();
  curMessageBusinessID = this.messageBusinessID.asObservable();
  private readStatus = new BehaviorSubject('');
  message = this.readStatus.asObservable();

  private bpUpdateStatus = new Subject();
  UpdatedBp = this.bpUpdateStatus.asObservable();


  private name = new BehaviorSubject('');
  nameChange = this.name.asObservable();



  creatorID: string;
  private supportedDocumentType = new BehaviorSubject('');
  supportedDocumentTypemsg = this.supportedDocumentType.asObservable();

  private supportedUploadTypes = new BehaviorSubject('');
  supportedUploadTypesData = this.supportedUploadTypes.asObservable();


  private supportedProdUploadTypes = new BehaviorSubject('');
  supportedPordUploadTypesData = this.supportedProdUploadTypes.asObservable();

  private MenuStatus = new BehaviorSubject('');
  selectedMenu = this.MenuStatus.asObservable();

  private readMessage = new BehaviorSubject('');
  messageRead = this.readMessage.asObservable();



  detechChangeMenu(event) {
    this.MenuStatus.next(event);
  }

  detectMessage(event){
    this.readMessage.next(event);
   }

  detechNameChange(event) {
    this.name.next(event);
  }

  changeBusinessID(ID: string) {
    this.businessID.next(ID);
  }
  changeBusinessIDForResetMessage(ID: string) {
    this.businessIDForResetMessage.next(ID);
  }
  changeBusinessPermissions(permissions) {
    this.businessPermissions.next(permissions);
  }
  getBusinessPermissions() {
    let value;
    this.businessPermissions.subscribe((val) => {
      value = val
    })
    return value;
  }
  changeMessageBusinessID(ID: string) {
    this.messageBusinessID.next(ID);
  }
  changeProfilepic(event) {
    this.profileChange.next(event);
  }
  ReadMessage(event) {
    this.readStatus.next(event);
  }

  updatedBusiness(event) {
    this.bpUpdateStatus.next(event);
  }


  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  Calculate_Percentage(val1, val2) {
    const total = parseFloat(val1) + parseFloat(val2);
    let percentage = isNaN((val1 / total) * 100) ? 50 : (val1 / total) * 100;
    const minper = 26;
    if (percentage <= minper && percentage !== 0) {
      percentage = minper;
    } else if (percentage >= (100 - minper) && percentage !== 100) {
      percentage = (100 - minper);
    }
    return { percentage, 'percentage2': 100 - percentage };
  }
  removeEmptyKey(dataSet: any[]) {
    const result = [];
    dataSet.map((x) => {
      const keys = Object.keys(x);
      keys.map((key) => {
        if (!x[key] || x[key] === '') {
          delete x[key];
        }
      });
      result.push(x);
    });
    return result;
  }
  getItemByID(dataset: any[], key: string, id: string) {

    let result = {};

    dataset.map((x) => {
      if (x[key] === id) {
        result = x;
      }
    });

    return result;
  }
  formatDate(date, format) {
     if (!date || date === '') {
      return date;
    }
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    let formatedDate: string;
    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }
    switch (format) {
      case 'yyyy-mm-dd':
        formatedDate = yyyy + '-' + mm + '-' + dd;
        break;
      case 'yyyy/mm/dd':
        formatedDate = yyyy + '/' + mm + '/' + dd;
        break;
      default:
        break;
    }
    return formatedDate;
  }
  getGreatestLineStep(Data: [], step: number) {
    const greater = [];

    Data.map((x) => {
      greater.push(Math.max.apply(null, x['data']));
    });
    const max = Math.max.apply(null, greater);
    return Math.ceil(max / step) * step;
  }
  getSum(total, num) {
    return total + num;
  }
  getBarChartData(bcd: any, Type: string,) {

    const steps = [1, 2, 5, 10, 20, 25, 40, 50, 100];
    let step = 100;





    const temp = [];
    const resultChart = [];
    const total = [];
    bcd['data'].map((x, i) => {
      total.push(x.reduce(this.getSum));
      x.map((y, j) => {
        if (!temp[j]) {
          temp[j] = [];
        }
        temp[j].push(y);
      });
    });
    temp.map((x, i) => {
      const chrtObj = {
        data: x
        , label: bcd['key'][i]
        , type: 'bar'
        , stack: '1',
        borderWidth: 0
      };
      resultChart.push(chrtObj);
    });
    const greater = Math.max.apply(null, total);
    for (let i = 0; i < steps.length; i++) {

      if (steps[i] * 8 > greater) {
        step = steps[i];
        break;
      }
    }
    const maxvalue = Math.ceil(greater / step) * step;
    const intervalCount = Math.ceil((maxvalue / 8) / step) * step;
    return {
      Data: resultChart
      , Labels: bcd['labels']
      , Total: maxvalue === 0 ? 8 : intervalCount * 8
      , intervalCount: intervalCount === 0 ? 1 : intervalCount,



    };
  }
  getImageURL(image: any) {
   return image.data.fileurl;
  }
  download(image: any) { 

  //   // ..............s3.....
  //       fetch(image.data.fileurl)
  // .then(res => res.blob())
  // .then(blob => {  
  //  const blobs = window.URL.createObjectURL(blob); 
  //  window.URL.createObjectURL(blob);
  // });
    // const url = window.URL.createObjectURL(image);
    window.open(image.data.fileurl)
  }
  tableParser(table) {
    const tabData = JSON.stringify(table);
    return JSON.parse(tabData);
  }
  tableParserw(table) {
    const tabData = JSON.stringify(table);
    return table
  }

  getUserOptions(data) {

    data.map((x, i) => {
      const isBuyer = x.buyer_bp_business_id === this.businessID['_value'];
      const isSeller = x.seller_bp_business_id === this.businessID['_value'];
      const buyerStatus = x.buyer_status;
      const sellerStatus = x.seller_status;
      const editingBusiness = x.editing_bp_business_id;
      const isSellerEditing = x.is_seller_editing;

      const isDraftByMyBiz = isSeller ? sellerStatus === 'Draft' : buyerStatus === 'Draft';
      const isApprovalPendingByMyBiz = isSeller ? sellerStatus === 'Approval Pending' : buyerStatus === 'Approval Pending';
      const isRejectionPendingByMyBiz = isSeller ? sellerStatus === 'Rejection Pending' : buyerStatus === 'Rejection Pending';
      const isRejectedByMyBiz = isSeller ? sellerStatus === 'Rejected' : buyerStatus === 'Rejected';
      const isApprovedMyBiz = isSeller ? sellerStatus === 'Approved' : buyerStatus === 'Approved';
      const isVoidedMyBiz = isSeller ? sellerStatus === 'Voided' : buyerStatus === 'Voided';
      const isVoidPendingByMyBiz = isSeller ? sellerStatus === 'Void Pending' : buyerStatus === 'Void Pending';
      const isBothApproved = sellerStatus === 'Approved' && buyerStatus === 'Approved';
      const isBothRejected = sellerStatus === 'Rejected' && buyerStatus === 'Rejected';
      const isBothVoided = sellerStatus === 'Voided' && buyerStatus === 'Voided';


      let isBeingEdited = false;
      let isEditedByMyBiz = false;
      if (editingBusiness) {
        isBeingEdited = true;
        isEditedByMyBiz = isSeller ? isSellerEditing === 'Y' : isSellerEditing === 'N';
      }
      if (isDraftByMyBiz || isApprovalPendingByMyBiz || isBeingEdited && isEditedByMyBiz) {
        x['isApprovable'] = true;
      }
      if (isRejectionPendingByMyBiz || isApprovalPendingByMyBiz) {
        x['isRejectable'] = true;
      }
      if (isRejectionPendingByMyBiz ||
        isRejectedByMyBiz ||
        isDraftByMyBiz ||
        isApprovalPendingByMyBiz ||
        isApprovedMyBiz ||
        isBeingEdited && isEditedByMyBiz
      ) {
        x['isEditable'] = true;
        x['isVoidable'] = true;

      }

      x['isVoidable'] = x['isVoidable'] || isVoidPendingByMyBiz;

    });
    return data;


  }


  ReturnValue(array, key, value, retval) {

    let Value = '';
    array.map((x) => {
      if (x[key] === value) {
        Value = x[retval];
      }
    });

    return Value;
  }


  hasKey(data, key: string) {
    return data.hasOwnProperty(key);
  }
  getFIleSize(fileList: {}) {
    let size = 0;
    for (const key in fileList) {
      if (fileList.hasOwnProperty(key)) {
        size += fileList[key].size;
      }
    }
    return size;
  }
  isValidFiles(fileExt: string[], fileList: {}) {
    let flag = true; 

    for (const key in fileList) {
      if (fileList.hasOwnProperty(key)) {
        const fileName = fileList[key]['name'];
        const filenameSplit = fileName.split('.');
        const extension = filenameSplit.pop().toUpperCase();
        const toUpper = function (x) {
          return x.toUpperCase();
        };
        const fileExtUpper = fileExt.map(toUpper);

        if (!fileExtUpper.includes(extension)) {
          flag = false;
          break;
        }
      }
    }
    return flag;

  }

  getDefaultField(array: any[], key: string, value: string) {
    return array.find((x) => x[key] === value);
  }
  goToBack() {
    this._location.back();
  }
  getAdvancedFilterData(array) {
    const opList = {
      LIST: [
        { name: 'Equal', value: '=' }
        , { name: 'Not Equal', value: '!=' }
      ]
      , DATE: [
        { name: 'Equal', value: '=' }
        , { name: 'Not Equal', value: '!=' }
      ]
      , NUMERIC: [
        { name: 'Equal', value: '=' }
        , { name: 'Less than', value: '<' }
        , { name: 'Greater than', value: '>' }
        , { name: 'Less than  or equal to', value: '<=' }
        , { name: 'Greater than  or equal to', value: '>=' }
      ]
      , STRING: [
        { name: 'Equal', value: '=' }
        , { name: 'Not Equal', value: '!=' }
        , { name: 'Like', value: '%' }
      ]
    };
    const getLists = (lists) => {
      return lists.map((y) => {
        return { name: y, value: y };
      });
    };
    const advFilter = [];
    array.map((x) => {
      const obj = {
        name: x.DISPLAY_NAME
        , type: x.TYPE
        , value: x.KEY
        , operator: opList[x.TYPE]
      };
      switch (x.TYPE) {
        case 'LIST':
          obj.type = 'Status';
          obj['status'] = getLists(x.VALUES);
          break;
        case 'DATE':
          obj.type = 'Date';
          obj['operator'] = [];
          obj['dateFrom'] = x.KEY + '_from';
          obj['dateTo'] = x.KEY + '_to';
          break;

        default:
          break;
      }
      advFilter.push(obj);
    });
    return advFilter;
  }



  getDocType(msg) {
    this.supportedDocumentType.next(msg);
  }



  getUploadTypes(data) {
    this.supportedUploadTypes.next(data);
  }
  getProdUploadFileTypes(data) {
    this.supportedProdUploadTypes.next(data);
  }

  //#region donut chart options
  get donutInterval() {
    const interval = [
      {
        name: 'Current week'
        , value: { interval: 'week' }
      }
      , {
        name: 'Current month'
        , value: { interval: 'month' }
      }
      , {
        name: 'Current quarter'
        , value: { interval: 'quarter' }
      }
      , {
        name: 'Current year'
        , value: { interval: 'year' }
      }
      , {
        name: 'past 7 days'
        , value: { interval: 'past', noofdays: 7 }
      }
      , {
        name: 'past 30 days'
        , value: { interval: 'past', noofdays: 30 }
      }
      , {
        name: 'past 90 days'
        , value: { interval: 'past', noofdays: 90 }
      }
      , {
        name: 'past 365 days'
        , value: { interval: 'past', noofdays: 365 }
      }
    ];
    return interval;
  }
  //#endregion



  isAllread(data) {
    const isRead = data.some((x) => x['has_been_seen'] === 'Y');
     return isRead;

  }
  get titleDefault() {
    return {
      BasicDetails: { enabled: true }
      , CannabisRelatedDetails: { enabled: true }
      , BusinessBankAccountDetails: { enabled: true }
      , ContactDetails: { enabled: true }
      , KeyPersonRegistration: { enabled: true }
      , DeliveryAddress: { enabled: true }
      , EmailNotificationConfiguration: { enabled: true }
      , SMSNotificationConfiguration: { enabled: true }
      , SecurityDetails: { enabled: true }
      , ProspectDetails: { enabled: true }
      , fileManagement: {enabled: true}
      , matric: {enabled: true}
      , bankDetails: {enabled: true}
    };
  }
  getTitleNo(config) {
    let count = 1;
    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (config[key].enabled) {
          config[key]['slNo'] = count;
          count++;
        }
      }
    }
    return config;
  }
  getFilterTrimmed(array: any[]) {
    array.map(x => x['value'] = x['value'].trim().replace(/\,/g,''));


    // array.map((x)=>{
    //   x['value'] = x['value'].trim().replace(/\,/g,'');
    // })
    return array;
  }



  getDisablecCols(data) {
     data.map((y) => {
      disableCOloumns.map((x) => {
        if (y.value === x) {
          y['disableCol'] = true;
        }
      });
    });

    return data;

  }


  generateMaxInterval(data) {
    const steps = [1, 2, 5, 10, 20, 25, 40, 50, 100];
    let step = 100;
    let totals = [];
    data.map((x, i) => {
      if (!x.hidden) {
        if (totals.length === 0) {
          totals = [...x['data']]
        } else {
          x.data.map((y, j) => {
            totals[j] += y
          })
        }
      }
    });

    const greater = Math.max.apply(0, totals);
    for (let i = 0; i < steps.length; i++) {

      if (steps[i] * 8 > greater) {
        step = steps[i];
        break;
      }
    }


    const maxvalue = isFinite(greater) ? Math.ceil(greater / step) * step : 0;
    let intervalCount = Math.ceil((maxvalue / 8) / step) * step;
    intervalCount = intervalCount === 0 ? 1 : intervalCount;

    return {
      maxvalue, intervalCount
    }
  }

  changeToFromData(jsonData) {
    const formData = new FormData();
    for (const key in jsonData) {
      if (jsonData.hasOwnProperty(key)) {
        const value = typeof jsonData[key] === 'object' ? JSON.stringify(jsonData[key]) : jsonData[key];
        formData.append(key, value);
      }
    }
    return formData;
  }
  addAttr() {
    setTimeout(() => {
      const anchor = document.querySelectorAll('.termsNcond a');
      anchor.forEach(x => {
        const href = x.getAttribute('href');
        x.setAttribute('target', '_blank');
        if (href && !href.includes('http')) {
          x.setAttribute('href', `http://${href}`);
        } else if (!href) {
          x.setAttribute('href', `404`);
        }
      });
    });
  }
}
