import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-un-auth',
  templateUrl: './un-auth.component.html',
  styleUrls: ['./un-auth.component.css']
})
export class UnAuthComponent implements OnInit {

  constructor( public dialogRef: MatDialogRef<any>
    , @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    
  }

}
