import { AuthGuard } from './services/auth-guard.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { from } from 'rxjs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthService } from './services/auth-service.service';
import { HttpClientModule } from '@angular/common/http';


import { CookieService } from 'ngx-cookie-service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnAuthComponent } from './shared/un-auth/un-auth.component';
import { DatePipe } from '@angular/common'; 
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MdComponentsModule } from './shared/md-components/md-components.module';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormField } from '@angular/material/form-field';



@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    UnAuthComponent, 
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxSpinnerModule,
    HttpClientModule,
    MatSnackBarModule,
    MdComponentsModule,
    MatDialogModule
    ],
    entryComponents: [UnAuthComponent],
  providers: [CookieService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
