export const  attachmentTable: string[] = [ 'No' ,'Name',  'Action'];
export const disableCOloumns = ['Order ID'];
// license search
export const licenseSearch_keypair = [{
    key: 'City',
    value: 'city'
},
{
    key: 'Status',
    value: 'bp_license_status_name'
},
{
    key: 'License Number',
    value: 'bp_license_no'
}];