export const environment = {
  production: true,
  name: 'development',
  BACKEND: 'https://api-am-dev.standardc.io/',
  FRONTEND: 'https://am-dev.standardc.io/',
  //IDme Client-id
  client_id: '655e54c855a52bca444898f7ed96e6e9',
  idme_scope: "identity_dev",
  idme_url: 'https://api.idmelabs.com',
  idme_redirect_uri: 'https://am-dev.standardc.io/verification',
  reCaptchaSiteKey : '6LdCJJIUAAAAAC5VTUgcJlI5g1K-l9T5P9sw1bz5'
};